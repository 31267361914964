/* eslint-disable tailwindcss/no-custom-classname */
import clsx from 'clsx'

import { Bolds, Colors, Positions, Sizes } from '@/components/types'

export interface ITitle {
  isVisible: boolean
  title: string
  fontSize?: Sizes
  fontColor?: Colors
  fontBold?: Bolds
  position?: Positions
}

const Title = ({
  isVisible,
  title,
  fontBold = 'bold',
  fontColor = 'black',
  fontSize = 'lg',
  position = 'left',
}: ITitle) => {
  return (
    <section
      className={clsx(
        !isVisible && 'clipped',
        'flex w-full',
        `title-position-${position}`
      )}
    >
      <h1
        className={clsx(
          `title-font-size-${fontSize}`,
          `title-font-bold-${fontBold}`,
          `title-font-color-${fontColor}`
        )}
      >
        {title}
      </h1>
    </section>
  )
}

export default Title
