import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import clsx from 'clsx'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'

import { useSearchHome } from '@/context/SearchHomeContext'
import { ComponentSearchHomeEntity } from '@/types/strapi/Components.types'

interface SearchInputHome {
  setServiceFilter: Dispatch<SetStateAction<string>>
  serviceData: ComponentSearchHomeEntity[]
  serviceFilter: string
}

const SearchInputHome = ({
  setServiceFilter,
  serviceData,
  serviceFilter,
}: SearchInputHome) => {
  const { setFilterServiceData, filterServiceData } = useSearchHome()
  const router = useRouter()
  const [historyFilter, setHistoryFilter] = useState<
    ComponentSearchHomeEntity[]
  >([])
  const [showHistory, setShowHistory] = useState(false)
  const filteredServiceData =
    serviceFilter !== '' && serviceFilter.length > 2
      ? serviceData.filter((service) => {
          if (!service.isActive) {
            return false
          }

          const normalizedServiceLabel = service.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

          const normalizedFilter = serviceFilter
            .toLowerCase()
            .replace(/\s+/g, '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

          return normalizedServiceLabel.includes(normalizedFilter)
        })
      : ''

  useEffect(() => {
    if (filterServiceData) {
      if (localStorage.getItem('filterServiceDataHistory') === null) {
        localStorage.setItem(
          'filterServiceDataHistory',
          JSON.stringify([filterServiceData])
        )
      } else {
        const filterServiceDataHistory = JSON.parse(
          localStorage.getItem('filterServiceDataHistory') || ''
        )
        const isStoredHistory = filterServiceDataHistory.some(
          (item: { eanCode: string }) =>
            item.eanCode === filterServiceData.eanCode
        )
        if (!isStoredHistory) {
          filterServiceDataHistory.unshift(filterServiceData)
          if (filterServiceDataHistory.length > 4)
            filterServiceDataHistory.pop()

          localStorage.setItem(
            'filterServiceDataHistory',
            JSON.stringify(filterServiceDataHistory)
          )
        }
      }
    }
  }, [filterServiceData])

  useEffect(() => {
    if (localStorage.getItem('filterServiceDataHistory') !== null) {
      const filterServiceDataHistory = JSON.parse(
        localStorage.getItem('filterServiceDataHistory') || ''
      )
      setHistoryFilter(filterServiceDataHistory)
    }
  }, [])

  return (
    <div className="mt-4 w-full">
      <div className="w-full">
        <Combobox value={serviceFilter} onChange={setServiceFilter}>
          <div
            className={clsx(
              'flex w-full flex-col items-start rounded-lg bg-white px-3 py-2 transition-all',
              serviceData ? 'border border-tamagotchi p-3' : 'border-2'
            )}
          >
            <div className={clsx('my-1 flex w-full items-center bg-white p-1')}>
              <FontAwesomeIcon
                className="w-10 px-2 opacity-20"
                icon={faSearch}
              />
              <Combobox.Input
                placeholder="¿Qué deseas buscar?"
                onChange={(event) => {
                  setServiceFilter(event.target.value)
                  setShowHistory(true)
                }}
                onClick={() => setShowHistory(true)}
                onFocus={() => {
                  setShowHistory(true)
                }}
                onBlur={() => {
                  setShowHistory(false)
                }}
                className="w-full focus:outline-none"
                type="text"
                autoComplete="off"
              />
            </div>
            <Combobox.Options className="w-full">
              <div
                className={clsx(
                  'ml-1 flex w-full items-start rounded-b-3xl bg-white transition-all'
                )}
              >
                <div
                  className={clsx(
                    'flex w-full flex-col gap-4',
                    filteredServiceData &&
                      filteredServiceData.length > 0 &&
                      'p-4'
                  )}
                >
                  {filteredServiceData &&
                    filteredServiceData.map(
                      ({ eanCode, label, slug, isActive }, key) => (
                        <div
                          key={key}
                          className="w-full cursor-pointer border-b py-2"
                          onClick={() => {
                            router.push(slug)
                            setFilterServiceData({
                              eanCode,
                              label,
                              slug,
                              isActive,
                            })
                          }}
                        >
                          {isActive && <p>{label}</p>}
                        </div>
                      )
                    )}
                  <div
                    className={clsx(
                      showHistory && !filteredServiceData && 'p-4'
                    )}
                  >
                    {!filteredServiceData &&
                      showHistory &&
                      historyFilter &&
                      historyFilter.map(
                        (item: ComponentSearchHomeEntity, key: number) => (
                          <div
                            key={key}
                            className="w-full cursor-pointer border-b py-2"
                            onClick={() => {
                              router.push(item.slug)
                              setFilterServiceData({
                                isActive: item.isActive,
                                eanCode: item.eanCode,
                                label: item.label,
                                slug: item.slug,
                              })
                            }}
                          >
                            {item.label}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
    </div>
  )
}

export default SearchInputHome
