import { useState } from 'react'
import Image from 'next/image'

import clsx from 'clsx'

import { StrapiUploadFileEntity } from '@/types/strapi'

export interface ServiceCardProps {
  circleColor?: string
  icon: Partial<StrapiUploadFileEntity>
  title: string
  description?: string
}

const ServiceCard = ({
  circleColor = 'bg-purple-200',
  icon,
  title,
  description,
}: ServiceCardProps) => {
  const [pressed, setPressed] = useState(false)
  const handleButtonClick = () => {
    setPressed(true)
    setTimeout(() => setPressed(false), 300)
  }

  return (
    <div
      onClick={handleButtonClick}
      className={clsx(
        'h-full min-w-[72px] transition duration-500 ease-in-out',
        pressed ? 'scale-95' : 'scale-100'
      )}
    >
      <div className="card shadow-card h-full w-full cursor-pointer">
        <div className="flex flex-col items-center justify-center gap-3">
          <div
            style={{
              background: circleColor,
            }}
            className="flex h-12 w-12 items-center justify-center rounded-full"
          >
            <div className="flex h-10 items-center justify-center">
              <Image
                height={25}
                width={25}
                src={icon?.url || '/default-icon.png'}
                alt="Service card icon"
              />
            </div>
          </div>
          {description ? (
            <>
              <span className="text-xs font-bold">{title}</span>
              <span className="text-sm">{description}</span>
            </>
          ) : (
            <span className="text-center text-sm font-medium md:text-base">
              {title}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
